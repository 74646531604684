<template>
  <div class="a-page container">
    <a-header backPath="/zoznam">
      <template v-slot:center>
        <b-nav pills>
          <b-nav-item :to="`/test/${examId || ''}`">Otázky</b-nav-item>
          <b-nav-item v-if="examId" :to="`/test/${examId}/vysledky`">Výsledky</b-nav-item>
        </b-nav>
      </template>
    </a-header>

    <router-view />
  </div>
</template>

<script>
export default {

  components: {
    'a-header' : () => import('@/plugins/app/_layout/a-header')
  },

  data() {
    return {
      examId: this.$route.params.id
    }
  }
}
</script>

<style scoped>

</style>